'use strict';

Gri.module({
  name: 'infobox-beta',
  ieVersion: null,
  $el: $('.infobox-beta'),
  container: '.infobox-beta',
  fn: function () {
    
  }
});
